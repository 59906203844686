import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import http from "../http";

export default function Home() {
  // const [users, setUsers] = useState([]);

  // useEffect(() => {
  //   fetchAllUsers();
  // }, []);

  // const fetchAllUsers = () => {
  //   http.get("/auth/users").then((res) => {
  //     setUsers(res.data);
  //   });
  // };

  // const deleteUser = (id) => {
  //   http.delete("/auth/users/" + id).then((res) => {
  //     fetchAllUsers();
  //   });
  // };

  return (
    <div>
      
      <Container>
        {/* <h2>Users listing ...</h2>
        <table className="table">
          <thead>
            <tr>
              <th>Sno.</th>
              <th>Name</th>
              <th>Email</th>
              {/* <th>Action</th> * /}
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={user.id}>
                <td>{++index}</td>
                <td>{user.name}</td>
                <td>{user.email}</td>
                {/* <td>
                  <Link
                    className="btn btn-info"
                    to={{ pathname: "/edit/" + user.id }}
                  >
                    Edit
                  </Link>
                  &nbsp;
                  <Link
                    className="btn btn-primary"
                    to={{ pathname: "/view/" + user.id }}
                  >
                    View
                  </Link>
                  &nbsp;
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                      deleteUser(user.id);
                    }}
                  >
                    Delete
                  </button>

                </td> * /}
              </tr>
            ))}
          </tbody>
        </table> */}
        <div className="row">
          <h2>Welcome to RullingStock</h2>

          <Link className="btn btn-info mt-5" to={`/login`}>Login</Link>
        </div>
      </Container>
    </div>
  );
}
