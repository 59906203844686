import { useState } from "react";
import { useLogin } from "../hooks/useLogin";

export default function Login() {

    const { login, error, isLoading } = useLogin();

    const [inputs, setInputs] = useState({});
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values,[name]:value}))
        // console.log("email: ", value)
    }

    const submitForm = async (e) =>{
        e.preventDefault()
        await login(inputs);
        /*
        http.post('/auth/login',inputs)
        .then((res)=>{
            console.log(inputs);

            if(res.status === 200){
                const userData = res.data;
                setUser(res.data);
                //setToken(userData.access_token);
                // console.log(userData.access_token, "====================setToken");

            }
        })
        */
    }
    return (
        <div className="row justify-content-center pt-5">
            <div className="col-sm-6">
                <h2>User Login</h2>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card p-4">
                            
                            <label>Email</label>
                            <input type="email" name="email" className="form-control mb-2"
                                value={inputs.email || ''}
                                onChange={handleChange}
                            />

                            <label>Password</label>
                            <input type="password" name="password" className="form-control mb-2"
                                value={inputs.password || ''}
                                onChange={handleChange}
                                />

                            <button type="button" onClick={submitForm} className="btn btn-info mt-2">Login</button>
                            { error && <div className="error">{error}</div>}
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

    )
}
