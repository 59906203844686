import React from 'react'
import ApexCharts from 'apexcharts'
import CharacterData from '../components/ChartLineColumnArea'
import ChartCandleStickDashboard from '../components/ChartCandleStickDashboard'
import ChartPeiDashboard from '../components/ChartPeiDashboard';


var options = {
  series: [{
  name: 'Website Blog',
  type: 'column',
  data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]
}, {
  name: 'Social Media',
  type: 'line',
  data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
}],
  chart: {
  height: 350,
  type: 'line',
},
stroke: {
  width: [0, 4]
},
title: {
  text: 'Traffic Sources'
},
dataLabels: {
  enabled: true,
  enabledOnSeries: [1]
},
labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
xaxis: {
  type: 'datetime'
},
yaxis: [{
  title: {
    text: 'Website Blog',
  },

}, {
  opposite: true,
  title: {
    text: 'Social Media'
  }
}]
};

var lineColumn = new ApexCharts(document.querySelector("#lineColumn"), options);
lineColumn.render();



var options = {
  series: [44, 55, 41, 17, 15],
  chart: {
  width: 380,
  type: 'donut',
},
plotOptions: {
  pie: {
    startAngle: -90,
    endAngle: 270
  }
},
dataLabels: {
  enabled: false
},
fill: {
  type: 'gradient',
},
legend: {
  formatter: function(val, opts) {
    return val + " - " + opts.w.globals.series[opts.seriesIndex]
  }
},
title: {
  text: 'Gradient Donut with custom Start-angle'
},
responsive: [{
  breakpoint: 480,
  options: {
    chart: {
      width: 200
    },
    legend: {
      position: 'bottom'
    }
  }
}]
};



export default function Dashboard() {
  return (
    <>
    <div className='row  ps-5 pe-5'>
      <h2>Dashboard</h2>
      <div className='col-md-4 card'><h3>DSE</h3><ChartCandleStickDashboard /></div>
      <div className='col-md-4 card text-center'>Real Time Line CHart(Auto Scroll)<CharacterData/></div> 

      <div className='col-md-4 card text-center'>Pie Chart<ChartPeiDashboard /></div>
      <div className='col-md-4 card text-center'>Live Categories Chart - 2 (Line Column Area)<div id='lineColumnArea'></div></div>
      <div className='col-md-4 card '>Live Categories Chart<div id='lineColumn'></div></div>
      
    </div>
    </>
    
  )
}
