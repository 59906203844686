import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import { useLogout } from "../hooks/useLogout";
import '../assets/style.css';

  export default function Header() {
    const { logout } = useLogout();
    const { user } = useAuthContext();
    const handleClick = () =>{
        // remove user from local storage, set auth state to null and redirect to login page
        logout();
    }
    // console.log(acc_token);
    return (
      <>
        <header>
           
          {/* <nav className="navbar navbar-expand navbar-dark bg-dark"> */}
          <nav className="navbar navbar-expand navbar-light bg-light ps-5">
          <div className="row">
            <div className="navbar-nav mr-auto">
              <li className="nav-item">
                {/* <Link to="/">
                  <h1>RullingStock</h1>

                </Link> */}
                <div class="logo-holder logo-6">
                  <Link to="/">
                    <h3>Rulling<span>Stock</span></h3>
                    <p>Think in innovative ways</p>

                  </Link>
                </div>
              </li>
               
              {
                !user && (
                  <>
                    <li className="nav-item pt-3">
                      <Link to={"/home"} className="nav-link">
                        Home
                      </Link>
                    </li>
                    
                    <li className="nav-item pt-3">
                      <Link to={"/login"} className="nav-link">
                        Login
                      </Link>
                    </li>
                    <li className="nav-item pt-3">
                      <Link to={"/signup"} className="nav-link">
                        Signup
                      </Link>
                    </li> 
                  </>
                )
              }
              {
                user && (
                    <>
                    <li className="nav-item  pt-3">
                      <Link to={`/ `} className="nav-link  pt-3">
                             
                      </Link>
                    </li> 
                    <li className="nav-item ml-3  pt-3">
                      <Link to={"/dashbrd"} className="nav-link">
                        Dashboard
                      </Link>
                    </li> 
                    <li className="nav-item pt-3">
                      <Link to={"/ptrade"} className="nav-link">
                        Prime Trade
                      </Link>
                    </li>
                    <li className="nav-item pt-3">
                      <Link to={"/mjr-trade"} className="nav-link">
                        Major Trade
                      </Link>
                    </li>
                    <li className="nav-item pt-3">
                        <span>{user.email}</span>
                        <Link className="nav-link btn btn-secondary" onClick={handleClick}>
                          Logout
                        </Link>
                    </li>

                    </>
                )
              }
              

            </div>
          </div>
          </nav>
        </header>
      </>
    );
  }

