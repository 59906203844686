import  { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import http from '../http'
import React  from 'react';
import Clock from 'react-live-clock';
import { useAuthContext } from '../hooks/useAuthContext';
import { Container } from 'react-bootstrap';
import loadingGif from '../assets/Wedges_locading.gif';

export default function ExploreItem(){
    let token = '';
    const navigate = useNavigate()
  
    const { user } = useAuthContext();
    if(user != null){
      token = user.token;
    }else{
      token = ''
      navigate('/login')
    }
    //console.log("User Token---------MAJOR Page:", token)
  
  const [itemInfo, setItemInfo] = useState([]);
  const [itemAnalysis, setItemAnalysis] = useState([]);
  const today = Date('Y-m-d');
  useEffect(()=>{
    //fetchPrvStoredPrimeTrade();
    if (user != null){
      fetchMajorTrade();
      fetchItemAnalyticsData();
    }
  },[user]);
  
  console.log(itemInfo.length, '==================Item Info Array')
  console.log(itemAnalysis.length, '==================Item Analysis Array')

  
  const config = {
      headers: { Authorization: `Bearer ${token}` }
  };

  const bodyParameters = {
    key: "value"
  };

  const tradingCode = useParams();
//   console.log(tradingCode.code, '==================Code')

  const fetchMajorTrade = () =>{
    http.get(`/explore-item/${tradingCode.code}`, bodyParameters, config).then(res=>{
        setItemInfo(res.data);//${tradingCode.code}
    })
  }
  const fetchItemAnalyticsData = () =>{
    http.get(`/item-analysis/${tradingCode.code}`, bodyParameters, config).then(res=>{
        setItemAnalysis(res.data);//${tradingCode.code}
    })
  }
  // majorItems !==null? (console.log(majorItems, "======== Major Items ")) : (console.log("Nooooooooooooooooo Major Items "))

  return (
    <div className=''>
        {/* {{ tradingCode }} */}

        <h2>{tradingCode.code}</h2>
        {
          !itemInfo &&  !itemAnalysis?
          <>
            <Container className='mt-5'>
              <img src='' alt="loading..." />
            </Container>
          </>
          :
          <>
           <div className="row">
            <div className="col-md-5 card">
                <div class="card-header">
                    <h4>Trading History</h4>
                </div>
                <div class="card-body">
                    {
                        itemInfo.length === 0 ?
                        <>
                            <Container className='mt-5'>
                                <img className='mt-5' src={loadingGif} alt="loading..." />
                                <p>loading...</p>
                            </Container>
                        </>
                        :
                        <>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Sl.</th>
                                        <th>Date</th>
                                        <th>Change %</th>
                                        <th>Change(tk)</th>
                                        <th>HIGH</th>
                                        <th>Low</th>
                                        <th>Close</th>
                                        <th>Volume</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                
                                    {itemInfo.map((item,index)=>(
                                        
                                        <tr key={item.id} >
                                            <td>{++index}</td>
                                            <td>{item.date}</td>
                                            {/* <td>{item.Change}</td> */}
                                            {
                                                // item.change > 0?(  ((LTP - YCP)x100/ YCP)
                                                ((item.ltp - item.ycp)*(100/item.ycp)) > 0?(
                                                    <>
                                                    <td style={{ fontWeight:'bold', color:'green' }}>{((item.ltp - item.ycp)*(100/item.ycp)).toFixed(2)}%</td>
                                                    <td style={{ fontWeight:'bold', color:'green' }}>{item.change} tk</td>
                                                    </>
                                                ):(
                                                    <>
                                                    <td style={{ fontWeight:'bold', color:'red' }}>{((item.ltp - item.ycp)*(100/item.ycp)).toFixed(2)}%</td>
                                                    <td style={{ fontWeight:'bold', color:'red' }}>{item.change} tk</td>
                                                    </>
                                                )
                                            }
                                            <td>{item.day_high}</td>
                                            <td>{item.day_low}</td>
                                            <td>{item.close_price}</td>
                                            <td>{item.volume}</td>
                                            <td>{item.total_value}</td>
                                        
                                        </tr>    
                                    ))}
                                </tbody>
                            </table>
                        </>
                    }
                    
                </div>
            </div>
            <div className="col-md-7 card" style={{ "border":"1px solid #CCC" }}>
            <div class="card-header">
                    <h3>Selection History</h3>
                </div>
                <div class="card-body">
                    {
                        itemAnalysis.length === 0 ?
                        <>
                            <Container className='mt-5'>
                                <img className='mt-5' src={loadingGif} alt="loading..." />
                                <p>loading...</p>
                            </Container>
                        </>
                        :
                        <>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Analysis</th>
                                    <th>Date</th>
                                    {/* <th>Change</th> */}
                                    <th>Lead at</th>
                                    <th>LTP</th>
                                    <th>YCP</th>
                                    <th>Y-High</th>
                                    <th>D-High</th>
                                    <th>D-Low</th>
                                    <th>D-Close</th>
                                    <th>Volume</th>
                                    <th>Y-Com.Vol</th>
                                    <th>Y-PrimeSlot.Vol</th>
                                    <th>Sec.Suprt</th>
                                </tr>
                            </thead>
                            <tbody>
                                {itemAnalysis.map((item,index)=>(
                                    <>
                                        {/* <td>{item.Change}</td> */}
                                        {
                                            item.selection_type_id === 1?(
                                                <tr key={item.id} style={{ fontWeight:'bold', backgroundColor:'#3CB371' }}>
                                                    <td ><span className='btn btn-success'>PRIME</span></td>
                                                    <td>{item.date}</td>
                                                    <td>{item.lead_at}</td>
                                                    <td>{item.ltp}</td>
                                                    <td>{item.ycp}</td>
                                                    <td>{item.y_high_price}</td>
                                                    <td>{item.current_high_price}</td>
                                                    <td>{item.day_low}</td>
                                                    <td>{item.close_price}</td>
                                                    <td>{item.current_volume}</td>
                                                    <td>{item.y_comparative_volume}</td>
                                                    <td>{item.y_prime_slot_volume}</td>
                                                    <td>{item.sector_support}</td>
                                                </tr>
                                            ):(
                                                <tr key={item.id} style={{ backgroundColor:'#f3eded' }}>
                                                    <td ><span className='btn btn-info'>MAJOR</span></td>
                                                    <td>{item.date}</td>
                                                    <td>{item.lead_at}</td>
                                                    <td>{item.ltp}</td>
                                                    <td>{item.ycp}</td>
                                                    <td>{item.y_high_price}</td>
                                                    <td>{item.current_high_price}</td>
                                                    <td>{item.day_low}</td>
                                                    <td>{item.close_price}</td>
                                                    <td>{item.current_volume}</td>
                                                    <td>{item.y_comparative_volume}</td>
                                                    <td>{item.y_prime_slot_volume}</td>
                                                    <td>{item.sector_support} %</td>
                                                </tr>
                                            )
                                        }
                                    </>
                                ))}
                            </tbody>
                        </table>
                        </>
                    }
                    
                </div>
                
            </div>
        </div>
          </>


        }
       
            
        </div>
  )

}

