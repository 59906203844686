import { useState } from "react";
import { useSignup } from "../hooks/useSignup";


export default function Signup() {

    const {signup, error, isLoading} = useSignup();

    const [inputs,setInputs] = useState({});
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values,[name]:value}))
        //console.log(inputs, "=======Inputs")
    }

    const submitForm = async (e) =>{
        e.preventDefault()

        await signup(inputs)
        // console.log("Signup Inputs:", inputs);
       
    }
    return (
        <div>
        <div className="offset-4">
            <h2>New User Registration</h2>
            <div className="row">
                <div className="col-sm-6">
                    <div className="card p-4">
                        <label>Name</label>
                        <input type="text" name="name" className="form-control mb-2"
                                value={inputs.name || ''}
                                onChange={handleChange}
                             />

                        <label>Email</label>
                        <input type="email" name="email" className="form-control mb-2"
                            value={inputs.email || ''}
                            onChange={handleChange}
                        />
                        <label>Phone Number</label>
                        <input type="text" name="phone" className="form-control mb-2"
                            value={inputs.phone || ''}
                            onChange={handleChange}
                        />
                        <label>Address</label>
                        <input type="text" name="address" className="form-control mb-2"
                            value={inputs.address || ''}
                            onChange={handleChange}
                        />

                        <label>Password</label>
                        <input type="password" name="password" className="form-control mb-2"
                            value={inputs.password || ''}
                            onChange={handleChange}
                             />
                        <label>Confirm Password</label>
                        <input type="password" name="password_confirmation" className="form-control mb-2"
                            value={inputs.password_confirmation || ''}
                            onChange={handleChange}
                             />
                        <button type="button" onClick={submitForm} className="btn btn-info mt-2"  disabled={isLoading}> Signup</button>
                        { error && <div className="error">{error}</div>}
                    </div>
                </div>
            </div>
        </div>
    </div>

    )
}