import { useState } from "react";
import http from "../http";
import { useAuthContext } from "./useAuthContext";

export const useSignup = () =>{
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const { dispatch } = useAuthContext()

    const signup = async (inputs) => {
        console.log("useSignUP Page Inputs:", inputs);
        setIsLoading(true)
        setError(null)

        http.post('/auth/register', inputs,{
            Headers :{ 'Content-Type': 'application/json'},
            // Body    : {inputs},
        })
        .then((res)=>{
            console.log(res, '===========Registration Response data')
            const response = res.data;
            if(res.status === 201){
                //Save the user to local Storage
                localStorage.setItem('user', JSON.stringify(response))

                //update Auth Context
                dispatch({type: 'LOGIN', payload: response})
                setIsLoading(false)
            }
            // else{
            //     setIsLoading(false)
            //     setError(response.error)
            // }

        }).catch(err => {
            if (err.response) {

                setIsLoading(false)
                setError(err.response.data)
                
                // The client was given an error response (5xx, 4xx)
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.headers);
            } else if (err.request) {
                // The client never received a response, and the request was never left
                console.log(err.request);

            } else {
                // Anything else
                console.log("Unknown error :( ");

            }
        });
    }

    return {signup, isLoading, error}
}