import { useState } from "react"
import { useNavigate } from "react-router-dom";
import http from "../http";
import { useAuthContext } from "./useAuthContext";


export const useLogin = () =>{
    const navigate = useNavigate()
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const { dispatch } = useAuthContext();

    const login = async (inputs) =>{
        // console.log("LoginPage inputs:", inputs)
        setIsLoading(true)
        setError(null)
        
        try {
            let res = await http.post('/auth/login', inputs, {
                Headers :{ 'Content-Type': 'application/json'},
            }).then((res)=>{
                console.log(res, '===========LOGIN Response data')
                const response = res.data;
                if(res.status === 200){
                    //Save the user to local Storage
                    localStorage.setItem('user', JSON.stringify(response))
    
                    //update Auth Context
                    dispatch({type: 'LOGIN', payload: response})
                    navigate('/dashbrd')
                    setIsLoading(false)
                }
    
            })
            // Work with the response...
        } catch (error) {
            if (error.response) {
                setIsLoading(false)
                setError(error.response.data)
                // The client was given an error response (5xx, 4xx)
                console.log(error.response.data);
                // console.log(err.response.status);
                // console.log(err.response.headers);
            } else if (error.request) {
                // The client never received a response, and the request was never left
                console.log(error.request);

            } else {
                // Anything else
                console.log("Unknown error :( ");

            }
        }
     
        /*
        
        http.post('/auth/login', inputs,{
            Headers :{ 'Content-Type': 'application/json'},
            // Body    : {inputs},
        })
        .then((res)=>{
            console.log(res, '===========LOGIN Response data')
            const response = res.data;
            if(res.status === 200){
                //Save the user to local Storage
                localStorage.setItem('user', JSON.stringify(response))

                //update Auth Context
                dispatch({type: 'LOGIN', payload: response})
                setIsLoading(false)
            }
            // else{
            //     setIsLoading(false)
            //     setError(response.error)
            // }

        }).catch(err => {
            if (err.response) {

                setIsLoading(false)
                setError(err.response.data)
                
                // The client was given an error response (5xx, 4xx)
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.headers);
            } else if (err.request) {
                // The client never received a response, and the request was never left
                console.log(err.request);

            } else {
                // Anything else
                console.log("Unknown error :( ");

            }
        });
        */
    }



    return {login, isLoading, error}

}