import React, { useEffect, useState } from 'react'
import ApexCharts from 'apexcharts'

const ChartPeiDashboard = () => {
  // const [averageTemp, setAverageTemp] = useState([]);

  useEffect(()=>{
    var options = {
      series: [44, 55, 13, 43, 22],
      chart: {
      width: 380,
      type: 'pie',
    },
    labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
    };

    var chart = new ApexCharts(document.querySelector("#chart"), options);
    chart.render();
  },[])

  // var chart = new ApexCharts(document.querySelector("#chart"), averageTemp);
  // chart.render();
      

  return (
    <div id='chart'></div>
  )
}
 
export default ChartPeiDashboard;